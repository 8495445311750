import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import Navbar from '../layouts/navbar'
import Loader from '../layouts/loader';
import apiService from '../../services/apiService';

const DashboardLayout = ({children}) => {
   // State to hold the token
   const [authToken, setAuthToken] = useState(null);
   const [loader, setLoader] = useState(true)

   useEffect(() => {
    const fetchTokenAndData = async () => {
      try {
        // Retrieve the token from the URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('ref');
  
        if (token) {
          // Decode and parse the token
          let decodedRef = decodeURIComponent(token);
          decodedRef = JSON.parse(decodedRef);
  
          // Update state with the token
          setAuthToken(decodedRef.token);
  
          // Save the token to local storage
          localStorage.setItem('authToken', decodedRef.token);
          sessionStorage.setItem("userDetail", JSON.stringify(decodedRef));
          localStorage.setItem("ORGID", decodedRef.org_id);
  
          // Fetch additional data using the token
          const response = await apiService.getv1(`get-org-details/${decodedRef?.org_id}`, decodedRef?.token);
  
          if (response?.status === 'success') {
            localStorage.setItem('orgInfo', JSON.stringify(response?.data));
  
            if (response?.data && response?.data?.org_image && response?.data?.org_image != null && response?.data?.org_image !="null") {
              localStorage.setItem('image', response?.data?.org_image);
            }
          }
  
          // Clear the URL parameters
          window.history.replaceState({}, document.title, window.location.pathname);
  
          // Set loader to false
          setLoader(false);
        } else {
          // No token found in URL parameters
          setLoader(false);
        }
      } catch (error) {
        // Handle errors, e.g., display an error message
        console.error('Error fetching token and data:', error);
        setLoader(false);
      }
    };
  
    // Call the fetchTokenAndData function
    fetchTokenAndData();
  
    // Include dependencies if needed (e.g., token, apiService, etc.)
  }, []); // Empty dependency array ensures the effect runs only once
  return (
    <>
       {loader ? <Loader/>
       :  <Navbar children={children}>
              <Outlet/>
          </Navbar>
       }
    </>
  )
}
export default DashboardLayout;