import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import apiService from '../../../services/apiService';
import { useNavigate } from 'react-router';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';


const UploadLogo = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [loader, setLoader] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate()

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.size <=  5 * 1024 * 1024) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
              setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
          } else {
            setSelectedFile(null);
            setPreviewUrl(null);
            CustomToast.error('Please choose a valid image file (max size: 5MB).');
          }
        } 

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.png', '.svg', '.jpg'],
        }
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <ul key={file.path}>
          <li>
            {file.path} - {file.size} bytes
            <ul>
              {errors.map((e) => (
                <li key={e.code}>{e.message}</li>
              ))}
            </ul>
          </li>
        </ul>
      ));

    const handleSubmit = (e) => {
        e.preventDefault();
       // console.log('File:', file);
    };

    const upDateData = async () => {
        try {
            if(!selectedFile) {
                CustomToast.error('Please choose a file');
                setLoader(false);
            } else {
                let getUserData = sessionStorage.getItem('userDetail')
                if(getUserData) {
                    getUserData = JSON.parse(getUserData)
                }
                setLoader(true);
                const formData = new FormData();
                formData.append('image', selectedFile);
                console.log(formData);
                const result = await apiService.patchV1(`update-org/${getUserData.org_id}`, formData);
                if (result && result.status === 'success')  {
                    const token = apiService.getAuthToken()
                    const orgResult = await apiService.getv1(`get-org-details/${getUserData.org_id}`, token);
                    if(orgResult && orgResult?.status == "success") {
                        localStorage.setItem('orgInfo', JSON.stringify(orgResult?.data))
                    }
                    CustomToast.success('Org image updated successfully')
                    // navigate('/')
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 1000);
                }
                setLoader(false);
            }
        } catch (error) {
            console.error(error);
            //alert('Something went wrong');
            setLoader(false);
            CustomToast.error('Something went wrong');
        }
    };
    

    return (
        <>
            <div className="card mb-4">
                <div className="card-body">
                    <h3 className="font-26 font-weight-600">Please upload your org. logo</h3>
                    <form className="text-end" onSubmit={(e) => e.preventDefault()}>
                        {loader ? (
                        <Loader />
                        ) : (
                        <div {...getRootProps()} className="upload-image text-center">
                            <input {...getInputProps()} />
                            <h5 className="primary_color font-26 font-weight-600">
                            <img src="/images/upload.png" alt="Upload Logo" />
                            Upload Logo
                            </h5>
                            <div className="image_upload my-4 mx-auto">
                            {previewUrl && (
                                <img
                                src={previewUrl}
                                alt="Selected File Preview"
                                className="mb-4"
                                style={{ maxWidth: '100%', maxHeight: '100px', marginTop: '10px' }}
                                />
                            )}
                            </div>
                            <p className="text-dark-gray mb-0">Size of the logo should not exceed 5MB</p>
                        </div>
                        )}
                        <button type="submit" onClick={upDateData} className="btn btn-md btn-primary  mt-4">
                        Save
                        </button>
                    </form>
                    {/* {fileRejectionItems.length > 0 && (
                        <div className="error">
                        <p>Error: Invalid file type or size. Please choose a valid image file (max size: 5MB).</p>
                        {fileRejectionItems}
                        </div>
                    )} */}
                </div>
            </div>
        </>
    )

}

export default UploadLogo;