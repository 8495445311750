import React, { useState } from 'react';
import CustomSelect from '../customSelect/customSelect';
import apiService from '../../../services/apiService';
import CustomToast from '../../Toast';
import { useLocation, useNavigate } from 'react-router-dom';


const Details = ({userInfo}) => {

    const [selectedGender, setSelectedGender] = useState('');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

      const ageGroupOptions = [
        { value: 'Boomers: 1946 - 1964', label: 'Boomers: 1946 - 1964' },
        { value: 'Gen X: 1965 - 1980', label: 'Gen X: 1965 - 1980' },
        { value: 'Millenials: 1981 - 1996', label: 'Millenials: 1981 - 1996' },
        { value: 'Gen Z: 1997 - 2012', label: 'Gen Z: 1997 - 2012' },
        { value: 'Alpha: 2013 - 2025', label: 'Alpha: 2013 - 2025' },
    ];
    
    const genderOptions = [
        { value: 'Woman', label: 'Woman' },
        { value: 'Man', label: 'Man' },
        { value: 'Others', label: 'Others' },
        { value: 'Prefer not to state', label: 'Prefer not to state'},
      ];

    const handleAgeGroupChange = (value) => {
        setSelectedAgeGroup(value);
    };

    const handleGenderChange = (value) => {
        setSelectedGender(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedAgeGroup || !selectedGender) {
            CustomToast.error('Please provide both your age and gender.');
            return;
        }
      
        // Create FormData object
        // const formData = new FormData();
        // formData.append('gender', selectedGender);
        // formData.append('age_group', selectedAgeGroup);
        // Make API call to update user profile
        let formData = {
            gender: selectedGender,
            age_group: selectedAgeGroup,
            email: userInfo?.email,
            uid: userInfo?.uid
        }
        try {
          const token = apiService.getAuthToken();
          const response = await apiService.putWithoutFormData('update-org-manage-user-info', formData, token);
      
          if (response && response.status === 'success') {
            const result = await apiService.get('get-org-user-info', token);
            if(result && result.status == "success") {
                localStorage.setItem('user_details', JSON.stringify(result?.data))
            }
            CustomToast.success('Profile updated successfully')
           // console.log('Data Saved Successfully');
           setTimeout(() => {
                window.location.reload(true);
            }, 1000);
          } else {
            // Handle API error
            // setError('Failed to update profile');
            CustomToast.error('Failed to update profile'); 
          }
        } catch (error) {
          console.log("sdasa", error)
          // Handle other errors (network, etc.)
        //   setError('An error occurred while updating profile');
        //   console.error(error);
          CustomToast.error('Failed to update profile'); 
          if (error == "Invalid token id!") {
            // Clear the token from local storage
            localStorage.removeItem('authToken');
            localStorage.removeItem('user_details');
            // Redirect to the original website
            // if (process.env.REACT_APP_ENV == "development") {
            //     window.location.href = 'https://staging.inclusional.com';
            // } else {
            //     window.location.href = 'https://site.inclusional.com';
            // }
            window.location.href = process.env.REACT_APP_LOGIN_URL
        }
        }
      };

    return (
        <>
            <div className="card mb-4">
                <div className="card-body">

                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group age">
                                    <label htmlFor="ageSelect" className="fw-bold font-20 mb-4">
                                        What is your age group?
                                    </label>
                                    <CustomSelect
                                        name="selectedAgeGroup"
                                        placeholder="Choose your Age Group"
                                        options={ageGroupOptions}
                                        value={selectedAgeGroup}
                                        onChange={handleAgeGroupChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group gender">
                                    <label htmlFor="genderSelect" className="fw-bold font-20 mb-4">
                                        What is your gender?
                                    </label>
                                    <CustomSelect
                                        name="selectedGender"
                                        placeholder="Select Gender"
                                        options={genderOptions}
                                        value={selectedGender}
                                        onChange={handleGenderChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 text-end'>
                             <button className="btn btn-md btn-primary text-right mt-4">Save</button>
                        </div>
                        
                    </form>

                </div>
            </div>
        </>
    )

}

export default Details;