import React, { useState, useEffect} from 'react';
import UploadLogo from './uploadLogo';
import Course from './course';
import Loader from '../../layouts/loader';
import apiService from '../../../services/apiService';

const Dashboard = () => {
    const [orgDetails, setOrgDetails] = useState()
    const [loader, setLoader] = useState(true);
    const [showUploadLogo, setShowUploadLogo] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchUserDetails = async () => {
            try {
                let orgInfo = JSON.parse(localStorage.getItem("orgInfo"));
                if(orgInfo && orgInfo?.org_image && orgInfo?.org_image != null &&orgInfo?.org_image !="null") {
                    setShowUploadLogo(true);
                } else {
                    const token = apiService.getAuthToken()
                    const org_id = localStorage.getItem("ORGID")
                    apiService.getv1(`get-org-details/${org_id}`, token).then((result)=> {
                        localStorage.setItem('orgInfo', JSON.stringify(result?.data))
                        if(result && result.status == "success" && result?.org_image && result?.org_image != null &&result?.org_image !="null") {
                            setShowUploadLogo(true);
                        }
                    })
                }
            } catch (error) {
                setLoader(false);
                console.error("Error fetching user details:", error);
            } finally {
                setLoader(false);
            }
        };

        fetchUserDetails();
    }, []);
    
    return (
       <>
       {loader && showUploadLogo ? <Loader /> : 
        <div className='page__main'>
            <div className="dashboard" style={{minHeight: '100vh'}}>
                {localStorage.getItem("image") || showUploadLogo ? null : <UploadLogo></UploadLogo>}
                <Course></Course>
            </div>
        </div>
       }
       </>
    )

}

export default Dashboard;