// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast-container--top-right {
    top: 1em !important;
}
  
.Toastify__toast-container { z-index: 999999 !important; }`, "",{"version":3,"sources":["webpack://./src/components/layouts/toast.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA,6BAA6B,0BAA0B,EAAE","sourcesContent":[".Toastify__toast-container--top-right {\n    top: 1em !important;\n}\n  \n.Toastify__toast-container { z-index: 999999 !important; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
