
const LOCAL_URL = 'http://localhost:3005/api/v2/org';
const CMS_URL = `${process.env.REACT_APP_LMS_API_URL}/v1/lms`
const BASE_URL = `${process.env.REACT_APP_ADMIN_API_URL}/api/v2/org`;
const BASE_URL_V1 = `${process.env.REACT_APP_ADMIN_API_URL}/api/v1/org`;
const BASE_URL_ANALYTICS = `${process.env.REACT_APP_ADMIN_API_URL}/api/v1/analytics`;

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    const errorMessage = errorData.message || 'Something went wrong';
    
    // Throw specific error types based on HTTP status code
    if (response.status === 401) {
      throw new UnauthorizedError(errorMessage);
    } else if (response.status === 403) {
      throw new ForbiddenError(errorMessage);
    } else {
      throw new Error(errorMessage);
    }
  }
  return response.json();
};

// Custom error classes
class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

const apiService = {
  get: async (endpoint, token) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      headers: {
        // 'ngrok-skip-browser-warning': 'true',
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
    });

    return handleResponse(response);
  },

  getv1: async (endpoint, token) => {
    const response = await fetch(`${BASE_URL_V1}/${endpoint}`, {
      headers: {
        // 'ngrok-skip-browser-warning': 'true',
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
    });

    return handleResponse(response);
  },

  post: async (endpoint, data, token) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        //'ngrok-skip-browser-warning': 'true',
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body:JSON.stringify(data),
    });

    return handleResponse(response);
  },

  postFormdata: async (endpoint, data, token) => {
    console.log(data,"kdmceiocmo");
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        //'ngrok-skip-browser-warning': 'true',
        token: `${token}`,
        // 'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: data,
    });

    return handleResponse(response);
  },

  delete: async (endpoint, data, token) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'DELETE',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: JSON.stringify(data)
    });

    return handleResponse(response);
  },

  put: async (endpoint, data, token) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'PUT',
      headers: {
        token: `${token}`,
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: data
    });

    return handleResponse(response);
  },
  
  putWithoutFormData: async (endpoint, data, token) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'PUT',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: JSON.stringify(data)
    });

    return handleResponse(response);
  },
  patchV1: async (endpoint, data, token) => {
    const response = await fetch(`${BASE_URL_V1}/${endpoint}`, {
      method: 'PATCH',
      headers: {
        // token: `${token}`,
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: data
    });

    return handleResponse(response);
  },

  getanalytics: async (endpoint, token) => {
    const response = await fetch(`${BASE_URL_ANALYTICS}/${endpoint}`, {
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
    });

    return handleResponse(response);
  },

  postLmsWithQuery: async (endpoint, token) => {
    const response = await fetch(`${CMS_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      }
    });

    return handleResponse(response);
  },

  postV1: async (endpoint, data, token) => {
    const response = await fetch(`${BASE_URL_V1}/${endpoint}`, {
      method: 'POST',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: JSON.stringify(data),
    });

    return handleResponse(response);
  },

  local: async (endpoint, data, token) => {
    const response = await fetch(`${LOCAL_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: JSON.stringify(data),
    });

    return handleResponse(response);
  },
  
  cmsget: async (endpoint, token) => {
    const response = await fetch(`${CMS_URL}/${endpoint}`, {
      headers: {
        // 'ngrok-skip-browser-warning': 'true',
        // token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
    });

    return handleResponse(response);
  },

  getLmsv1: async (endpoint, token) => {
    const response = await fetch(`${CMS_URL}/${endpoint}`, {
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
    });

    return handleResponse(response);
  },


  // Add other HTTP methods (put, delete, etc.) as needed

  // Function to set a token to be used in subsequent requests
  setAuthToken: (token) => {
    localStorage.setItem('authToken', token);
  },

  // Function to get the token
  getAuthToken: () => {
    if(localStorage.getItem('authToken')) {
      return localStorage.getItem('authToken')
    } else if(!window.location.pathname.startsWith('/accept-')) {
    //   if(process.env.REACT_APP_ENV == "development") {
    //     window.location.href = 'https://staging.inclusional.com';
    // } else {
    //     window.location.href = 'https://site.inclusional.com';
    // }
    window.location.href = process.env.REACT_APP_LOGIN_URL
    } else {
      return null
    }

  },

  // Function to remove the token (logout)
  removeAuthToken: () => {
    localStorage.removeItem('authToken');
  },
};

export default apiService;