import React from 'react';
import './login.css';

const LoginFooter = () => {
    return (
        <div className='login-footer bg-white'>
            <div className='container-fluid p-0'>
                <div className='row d-flex justify-content-between align-items-center'>
                    <div className='col-md-4'>
                        <p className='login-copyright mb-0'>©Inclusional. All Rights Reserved 2024</p>
                    </div>
                    <div className='col-md-5'>  
                        <p className='text-center mb-0'>
                            <a href="mailto:support@inclusional.com" target='_blank' className='login-link'>Contact Us</a>
                            <a href='/privacy' target='_blank' className='login-link'>Privacy Policy</a>
                            <a href='/term' target='_blank' className='login-link'>Terms & Conditions</a>
                        </p>
                    </div>
                    <div className='col-md-3'>
                        <div className='login-social-media'>
                            <a href="https://www.linkedin.com/company/inclusional/" target='_blank' className='login-social-link'>
                                <img src="/images/linkedin.png" className='login-social-icons'></img>
                            </a>
                            <a href="https://twitter.com/_inclusional/" target='_blank' className='login-social-link'>
                                <img src="/images/twitter.png" className='login-social-icons'></img>
                            </a>
                            <a href="https://www.instagram.com/inclusional/" target='_blank' className='login-social-link'>
                                <img src="/images/instagram.png" className='login-social-icons'></img>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100095283414576&is_tour_dismissed=true" target='_blank' className='login-social-link'>
                                <img src="/images/facebook.png" className='login-social-icons'></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginFooter;
