import React, {useState, useEffect, useRef} from "react";

const CustomSelect = ({ from, name, placeholder, options, value, onChange, disabled }) => {
    const [isActive, setIsActive] = useState(false);
    const selectRef = useRef(null);

  
    const handleSelectClick = () => {
      setIsActive(!isActive);
    };
  
    const handleOptionClick = (optionValue) => {
      setIsActive(false);
      if(from == "invite") {
        onChange(optionValue);
      } else {
        onChange(optionValue.value);
      }
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
          setIsActive(false);
        }
      };
  
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [isActive]);
  
    return (
      <div className="select" ref={selectRef}>
        <div className={`form-select ${isActive ? 'active' : ''}`} onClick={handleSelectClick}>
          {value || placeholder}
        </div>
        <ul className={`options ${isActive ? 'active' : ''}`}>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  export default CustomSelect;