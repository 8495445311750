import React, { useState, useEffect } from 'react';
import Routes from "./appRoutes";
import { ToastContainer } from 'react-toastify';

function App() {
  // State to hold the token
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    // Retrieve the token from the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('ref');

    // Update the state with the token
    if (token) {
      let decodedRef = decodeURIComponent(token);
      setAuthToken(decodedRef.token);
      decodedRef = JSON.parse(decodedRef)
      // Save the token to local storage
      localStorage.setItem('authToken', decodedRef.token);
      sessionStorage.setItem("userDetail",JSON.stringify(decodedRef))
    }
  }, []); // Empty dependency array ensures the effect runs only once

  return (
     // Pass the token to Routes component
    <>
     <ToastContainer />
     <Routes authToken={authToken} />
    </>
   
  );
}

export default App;