import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicRoutesLayout from "./components/shared/publicRoutesLayout";
import Login from "./components/login/login";
import DashboardLayout from "./components/shared/dashboardLayout";
import Dashboard from "./components/pages/dashboard/dashboard";
import OrganisationAdmin from "./components/pages/organisationAdmin/organisationAdmin";
import EditOrganisationProfile from "./components/pages/organisationAdmin/editOrganisationProfile";
import InviteToCourse from "./components/pages/inviteToCourse/inviteToCourse";
import PrivateLayout from "./components/shared/privateLayout";
import MyProfile from "./components/pages/myProfile/myProfile";
import ManageUser from "./components/pages/manageUser/manageUser";
import LearningSpace from "./components/pages/learningSpace/learningSpace";
import EditProfile from "./components/pages/manageUser/editProfile";
import CourseDetails from "./components/pages/courseDetails/courseDetails";
import AcceptInvitationComponent from "./components/layouts/accept_invitation";
import AcceptInvitationOrgComponent from "./components/layouts/accept_org_invitation";
import Stats from "./components/pages/dashboard/Stats";
import Messages from "./components/messages";

export default function AppRoutes() {
    return (
        <Router>
            <Routes>
            <Route
                    path="/"
                    element={<DashboardLayout />}
                >
                    <Route index element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/stats/:course_id/:course_name/:org_course_id" element={<Stats />} />
                    <Route path="/learning_space" element={<LearningSpace />} />
                    <Route path="/organisation_admin" element={<OrganisationAdmin />} />
                    <Route path="/manage_user" element={<ManageUser />} />
                </Route>

                <Route
                    path="/"
                    element= {<PrivateLayout />}
                >
                    <Route path= '/course_invite' element= {<InviteToCourse /> }></Route>
                    <Route path= '/my_profile' element= {<MyProfile /> }></Route>
                    <Route path= '/edit_org_profile' element= {<EditOrganisationProfile /> }></Route>
                    <Route path= '/manage_user/edit_profile' element= {<EditProfile /> }></Route>
                    <Route path= '/learning_space/course_details/:id' element= {<CourseDetails /> }></Route>
                </Route>

                <Route
                    path="/"
                    element= {<PublicRoutesLayout />}
                >
                    <Route path="/accept-invitation" element={<AcceptInvitationComponent/>}> </Route>
                    <Route path="/accept-org-invitation" element={<AcceptInvitationOrgComponent/>}> </Route>
                    <Route path="/messages" element={<Messages />}></Route>

                </Route>

            </Routes>
        </Router>
    );
}

