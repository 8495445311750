import { useEffect, useState, useCallback  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';
import OtpVerification from '../login/otpVerification';
import ErrorModal from './errorModal';
import CustomToast from '../Toast';
import Loader from './loader';
import Modal from 'react-modal'
import './toast.css';
import Messages from '../messages';
import { debounce } from '../../utils/debounce'; // Import the debounce function

const AcceptInvitationComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  const [error, setError] = useState('');
  const [otpData, setOtpData] = useState(null);
  const [showOtpComponent, setShowOtpComponent] = useState(false);
  const [loader, setLoader] = useState(false)
  const [retryAccept , setRetryAccept] = useState()
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [redirectTimer, setRedirectTimer] = useState(5);
  const [message, setMessage] = useState('')
  const [showErrorPopup, setShowErrorPopup] = useState(false)

   // Debounced version of the verifyToken function
   const debouncedVerifyToken = useCallback(
    debounce(async (paramsToken) => {
      try {
        setLoader(true);
        const data = {
          token: paramsToken,
          status: "ACCEPTED",
          dname: "OPPO",
          did: "2526"
        };
        setRetryAccept(data);
        const result = await apiService.post('accept-and-assign-course', data);
        if (result && result.status === "success") {
          setOtpData(result.data);
          setShowOtpComponent(true);
          CustomToast.success('OTP sent successfully');
        } else {
          setShowOtpComponent(false);
          setError('Failed to verify token');
          CustomToast.error('Failed to verify token');
        }
      } catch (error) {
        setShowOtpComponent(false);
        handleAPIError(error);
        CustomToast.error(error);
      } finally {
        setLoader(false);
      }
    }, 300), // Adjust the debounce delay as needed
    []
  );

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const token = params.get('token');

  //   if (token) {
  //     verifyToken(token);
  //   } else {
  //     navigate('/error');
  //   }
  // }, [location.search, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token && !otpData) {
      debouncedVerifyToken(token);
    } else if (!token) {
      navigate('/error');
    }
  }, [location.search, navigate, otpData, debouncedVerifyToken]);

  // const handleAPIError = (error) => {
  //   if (error && error.message && error.message.includes('Course already accepted')) {
  //     setShowErrorPopup(true)
  //     setMessage('Course already accepted please try login')
  //     openVideoModal()
  //   } else {
  //     setError(error.message || 'Something went wrong. Please try again.');
  //   }
  // };

  const handleAPIError = (error) => {
    if (error && error.message && error.message.includes('Course already accepted')) {
      setShowErrorPopup(true);
      setMessage('Course already accepted, please try login');
      setIsModalOpen(true);
    } else {
      setError(error.message || 'Something went wrong. Please try again.');
    }
  };

  // const verifyToken = async (paramsToken) => {
  //   try {
  //     // const appToken = apiService.getAuthToken();
  //     setLoader(true)
  //     const data = {
  //       token: paramsToken,
  //       status: "ACCEPTED",
  //       dname: "OPPO",
  //       did: "2526"
  //     };
  //     setRetryAccept(data)
  //     const result = await apiService.post('accept-and-assign-course', data);
  //     if (result && result.status === "success") {
  //       setOtpData(result.data);
  //       setShowOtpComponent(true);
  //       CustomToast.success('OTP send successfully')
  //       setLoader(false)
  //     } else {
  //       setShowOtpComponent(false);
  //       setError('Failed to verify token');
  //       CustomToast.error('Failed to verify token');
  //       setLoader(false)
  //     }
  //   } catch (error) {
  //     setShowOtpComponent(false);
  //     console.log(error);
  //     handleAPIError(error);
  //     CustomToast.error(error);
  //     setLoader(false)
  //   }
  // };

  const openVideoModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRedirectTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timerInterval);
  }, []);

  // useEffect(() => {
  //   console.log("dsas", process.env.REACT_APP_ENV)
  //   if (redirectTimer === 0 && showErrorPopup) {
  //     // Redirect and close the modal when the timer reaches 0
  //     window.location.href = process.env.REACT_APP_LOGIN_URL
  //     // process.env.REACT_APP_ENV == "development" ? window.location.href = 'https://staging.inclusional.com' : window.location.href =`https://site.inclusional.com`
  //     setIsModalOpen(false);
  //   }
  // }, [redirectTimer, navigate]);

  useEffect(() => {
    if (redirectTimer === 0 && showErrorPopup) {
      window.location.href = process.env.REACT_APP_LOGIN_URL;
      setIsModalOpen(false);
    }
  }, [redirectTimer, navigate, showErrorPopup]);

  const closeVideoModal = () => {
    setIsModalOpen(false);
  };

  function redirectTimes(seconds) {
    const minutes = Math.floor(seconds / 15);
    const remainingSeconds = seconds % 15;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  return (
    // <div>
    //   {error && (
    //    <Messages errorMessage={error} />
    //   )}

    //   {loader ? <Loader/> :
    //    showOtpComponent && (
    //     <div>
    //       <OtpVerification userDetails={otpData} retryAcceptProps= {retryAccept} />
    //     </div>
    //   )
    //   }
    //  {/* <button type="button" className="btn btn-primary" onClick={openVideoModal}>click here</button> */}
    //  {showErrorPopup && (
    //     <Messages
    //       modalMessage={message}
    //       redirectTimer={redirectTimer}
    //     />
    //   )}
    // </div>

    <div>
    {error && <Messages errorMessage={error} />}
    {loader ? <Loader /> :
      showOtpComponent && <OtpVerification userDetails={otpData} retryAcceptProps={retryAccept} />
    }
    {showErrorPopup && <Messages modalMessage={message} redirectTimer={redirectTimer} />}
  </div>
  );
};

export default AcceptInvitationComponent;