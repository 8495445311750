import React from 'react';
import LoginFooter from "./loginFooter";
import OtpVerification from './otpVerification';
import './login.css';

const Login = () => {
    return (
        <div className='login'>
            <div className='login-bottom'>
                <div className='container-fluid p-0'>
                    <div className='bg-login'>
                        <img src="/images/logo.svg" className='logo'></img>
                        <OtpVerification></OtpVerification>
                    </div>
                </div>
            </div>
            <LoginFooter></LoginFooter>
        </div>
    );
}

export default Login;