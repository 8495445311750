import React, { useEffect, useState, useRef } from "react";
import './courseDetails.css';
import apiService from "../../../services/apiService";
import { useParams } from "react-router-dom";
import CustomToast from "../../Toast";
import Loader from "../../layouts/loader";
import { useNavigate, useLocation} from 'react-router-dom';
import Modal from "react-modal";


const CourseDetails = () => {
    const [courseDetails, setCourseDetails] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const [loader, setLoader] = useState(false);
    const [expandedAccordian, setExpandedAccordian] = useState(0);
    const [expandAll, setExpandAll] = useState(false);
    const [showFullContent, setShowFullContent] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [showOverlay, setShowOverlay] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const videoRef = useRef(null);
    const limit = 350;
    const location = useLocation();
    const navigate = useNavigate();
    const course = location.state?.course

    const { id } = useParams()
    const getCourseDetails = (user_details) => {
        const token = apiService.getAuthToken()
        return apiService.getLmsv1(`getModuleStatus?course_meta_id=${id}&user_id=${user_details?.uid || "725e1bb6-77c7-4137-8482-0dc0fd74b14f"}`,token)
    }

    useEffect(() => {
        let user_details = localStorage.getItem('user_details')
        if (user_details) {
            user_details = JSON.parse(user_details)
            setUserInfo(user_details)
        }
        setLoader(true);
        getCourseDetails(user_details).then((data) => {
            setCourseDetails(data?.data)
            setLoader(false);
        }).catch((error) => {
            console.log(error);
            setLoader(false);
            CustomToast.error(error);
        })
    }, [])

    const startCourseList = async(data) => {
        const token = apiService.getAuthToken()
        await apiService.postLmsWithQuery(`startCourse?course_id=${data?.course_id}&user_id=${userInfo?.uid || "725e1bb6-77c7-4137-8482-0dc0fd74b14f"}`, token)
        navigate(`/learning_space/course_modules/${data?.modules[0]?.course_meta_id}`)
    }

    let course_meta_id
    if (courseDetails?.modules) {
        course_meta_id = courseDetails?.modules[0]?.course_meta_id;
    }

    const handleExpandAccordian = (index) => {
        if (expandAll) {
            setExpandAll(false);
          } else {
            setExpandedAccordian(index === expandedAccordian ? -1 : index);
          }
    };
    
    const handleExpandAll = () => {
        setExpandAll(!expandAll);
        setExpandedAccordian(expandAll ? -1 : 0);
    };

     /*

    const handleViewMore = () => {
        setShowFullContent(!showFullContent);
      };
    
      const truncateContent = (content, limit) => {
        if (!content || content.length <= limit) {
          return content;
        }
    
        const lastSpaceIndex = content.lastIndexOf(' ', limit);
        const truncatedContent = content.slice(0, lastSpaceIndex);
    
        return truncatedContent;
      };
    
     const renderContent = (content) => {
        const truncatedContent = truncateContent(content, limit);
        const remainingContent = content.slice(truncatedContent.length);
    
        return (
          <div dangerouslySetInnerHTML={{ __html: showFullContent ? content : truncatedContent }} />
        );
      };
    
      const combinedContent = courseDetails?.contents?.[1] ?? ''; 
     

      const handleTogglePlay = () => {
     
        const playBtn = document.getElementById('playBtn');

        setPlaying(!playing);
        setShowOverlay(false);
    
        const video = videoRef.current;

        if (video) {

          if (video.paused) {
            video.play();
            playBtn.classList.remove('playing');
            document.querySelector('.thumbnail_video').style.display = 'block';
            document.querySelector('.thumbnail_image').style.display = 'none';
          } else {
            video.pause();
            playBtn.classList.add('playing');
            document.querySelector('.thumbnail_video').style.display = 'none';
            document.querySelector('.thumbnail_image').style.display = 'block';
            setShowOverlay(true);
          }
          playBtn.classList.remove('playing');
          // document.querySelector('.thumbnail_image').style.display = 'block';

        }
      }; */

      const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const redirectUserToLMS = async (data) => {
        let userDetails = sessionStorage.getItem("userDetail")
        if(userDetails) {
            userDetails = JSON.parse(userDetails)
            userDetails['is_org_user'] = true
            const encodedToken = encodeURIComponent(JSON.stringify(userDetails));
            let url
            if(process.env.REACT_APP_ENV == 'development') {
                url = `https://learn.inclusional.com/learning_space/course_modules/${data?.course_meta_id}?ref=${encodedToken}`
            } else {
                url = `https://learn.inclusional.com/learning_space/course_modules/${data?.course_meta_id}?ref=${encodedToken}`
            }
            const newTab = window.open(url, '_blank');
            // Focus on the new tab (optional)
            if (newTab) {
            newTab.focus();
            } else {
            console.error('Failed to open new tab. Make sure pop-up blocker is disabled.');
            }
        } else {
            window.location.href = process.env.REACT_APP_LOGIN_URL
            // if(process.env.REACT_APP_ENV == 'development') {
            //     window.location.href = "https://staging.inclusional.com"
            // } else {
            //     window.location.href = "https://site.inclusional.com"
            // }
        }
    }

    const getInitials = (name) => {
        return name?.split(' ').map(word => word.charAt(0)).join('').toUpperCase() || '';
    };

    return (
        <>
           {loader ? <Loader />
           :  <div className='learning_course mt-5'>
           <div className="learning_course_banner pt-4 position-relative">
              
            {courseDetails.video ?
               (<div className={`video-container learning_course_video ${showOverlay ? '' : ''}`}>
                    <img src={courseDetails?.banner_image || course?.banner_image} alt="banner" className="w-100 thumbnail_image" style={{ height: 'auto', maxHeight: "500px", objectFit: 'fill' }} /> 
                    <video ref={videoRef} className="w-100 thumbnail_video" style={{ height: 'auto', maxHeight: "500px", objectFit: 'fill', display: "none" }} >
                        <source src={courseDetails?.video} type="video/mp4"  />
                    </video>
                    <button id="playBtn" className={`${playing ? 'playing' : ''}`} onClick={() => openModal()}><svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 180 180" fill="none"><g filter="url(#filter0_d_1545_54856)"><path d="M77.4505 113.254V55.3722L116.038 84.3132M90.3132 20C81.8674 20 73.5044 21.6635 65.7016 24.8955C57.8987 28.1276 50.8089 32.8649 44.8369 38.8369C32.7758 50.8979 26 67.2563 26 84.3132C26 101.37 32.7758 117.728 44.8369 129.789C50.8089 135.761 57.8987 140.499 65.7016 143.731C73.5044 146.963 81.8674 148.626 90.3132 148.626C107.37 148.626 123.728 141.85 135.789 129.789C147.85 117.728 154.626 101.37 154.626 84.3132C154.626 75.8674 152.963 67.5044 149.731 59.7016C146.499 51.8987 141.761 44.8089 135.789 38.8369C129.817 32.8649 122.728 28.1276 114.925 24.8955C107.122 21.6635 98.7589 20 90.3132 20Z" fill="white"></path></g><defs><filter id="filter0_d_1545_54856" x="0.718277" y="0.0407453" width="179.19" height="179.19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="5.32247"></feOffset><feGaussianBlur stdDeviation="12.6409"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_54856"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_54856" result="shape"></feBlend></filter></defs></svg></button>
                </div>) : (
                    <img src={courseDetails?.banner_image || course?.banner_image} alt="banner" className="w-100 thumbnail_image" style={{ height: 'auto', maxHeight: "500px", objectFit: 'fill' }} /> 
                )}
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Video Modal"
                            className="course-video w-75"
                        > <button className="btn btn-link close-btn text-end w-100" onClick={closeModal}><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                            <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                        </svg></button>
                            <div className="video-container">
                                <video
                                    ref={videoRef}
                                    controls
                                    autoPlay
                                    className="w-100"
                                    src={courseDetails?.video}
                                />
                                {/* <button id="playBtn1" className="play-btn1" onClick={() => openModal(courseId)}><svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 180 180" fill="none"><g filter="url(#filter0_d_1545_54856)"><path d="M77.4505 113.254V55.3722L116.038 84.3132M90.3132 20C81.8674 20 73.5044 21.6635 65.7016 24.8955C57.8987 28.1276 50.8089 32.8649 44.8369 38.8369C32.7758 50.8979 26 67.2563 26 84.3132C26 101.37 32.7758 117.728 44.8369 129.789C50.8089 135.761 57.8987 140.499 65.7016 143.731C73.5044 146.963 81.8674 148.626 90.3132 148.626C107.37 148.626 123.728 141.85 135.789 129.789C147.85 117.728 154.626 101.37 154.626 84.3132C154.626 75.8674 152.963 67.5044 149.731 59.7016C146.499 51.8987 141.761 44.8089 135.789 38.8369C129.817 32.8649 122.728 28.1276 114.925 24.8955C107.122 21.6635 98.7589 20 90.3132 20Z" fill="white"></path></g><defs><filter id="filter0_d_1545_54856" x="0.718277" y="0.0407453" width="179.19" height="179.19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="5.32247"></feOffset><feGaussianBlur stdDeviation="12.6409"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_54856"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_54856" result="shape"></feBlend></filter></defs></svg></button> */}
                            </div>
                        </Modal>
           
               <div className="banner-content">
                   <div className="row m-0">
                       <div className="col-lg-12 ms-auto">
                           <div className="card p-2">
                               <div className="card-body">
                                   {/*
                                    <nav aria-label="breadcrumb">
                                       <ol className="breadcrumb" style={{ fontSize: "14px"}}>
                                           <li className="breadcrumb-item"><a href="#">Learning Space</a></li>
                                           <li className="breadcrumb-item"><a href="#">Courses</a></li>
                                           <li className="breadcrumb-item active" aria-current="page">{courseDetails?.course_name}</li>
                                       </ol>
                                   </nav>
                                   */}
                                  
                                   <h3 className="font-weight-600 text-capitalize font-20">{courseDetails?.title}</h3>
                                   <p className='text-gray font-16 mb-2'> {courseDetails?.tagline || course?.tagline} </p>
                                   <p className='mb-0'>
                                       <span className="text-dark-gray text-capitalize pe-4"><img src="/images/time.svg" className="me-2" />{courseDetails?.duration} </span>
                                       <span className="text-dark-gray text-capitalize"><img src="/images/modules.png" className="me-2" />{courseDetails?.total_modules} Sections</span>
                                   </p>

                                   {course?.completed_user_list?.length == 0 && course?.completion_percent == 0 && course?.status == "notstarted" ?
                                       <div className="my-5">
                                           <p className="mb-3"> <img src="/images/account.svg" className="mb-20"></img></p>
                                           <p className='my-3 text-dark-gray'>Be the first one to complete the course</p>
                                       </div>
                                       : course?.completed_user_list?.length > 1 && course?.completed_user_list?.length <= 10 && course?.completion_percent == 100 && course?.status == "completed" ?
                                        <div className="my-4"> <div className='d-flex justify-content-start align-items-start'>
                                        {course?.completed_user_list?.slice(0, 5).map((user, index) => {
                                            return (
                                                <span className="mb-2" key={index}>
                                                    {user?.pic && user?.pic !== "null" && user?.pic !== null ? (
                                                        <img src={user?.pic} alt={user?.name} className="mb-2 me-0" style={{ height: "28px", width: "28px", borderRadius: "50%" }} />
                                                    ) : (
                                                        <span className='course-card-initials fw-bolder font-28 mb-2 me-0 text-white'>{getInitials(user?.name)}</span>
                                                    )}
                                                </span>
                                            );
                                        })}
                                        {course?.completed_user_list?.length > 5 && (
                                            <span className="mb-2 count-circle">
                                                {course?.completed_user_list?.length - 1}+
                                            </span>
                                        )}
                                    </div>
                                          <p>You are among the top 10 <span>{course?.completed_user_list?.length > 0 && course?.completed_user_list?.length !== 1 ? 'employees' : 'employee'}</span> to complete the course</p>
                                        </div>
                                        : course?.completed_user_list?.length === 1 && course?.completion_percent == 100 && course?.status == "completed" ?
                                        <div className="my-4">
                                            <div className='d-flex justify-content-start align-items-start'>
                                                {course?.completed_user_list?.slice(0, 5).map((user, index) => {
                                                    return (
                                                        <span className="mb-2" key={index}>
                                                            {user?.pic && user?.pic !== "null" && user?.pic !== null ? (
                                                                <img src={user?.pic} className="mb-2 me-1" style={{ height: "28px", width: "28px", borderRadius: "50%" }} />
                                                            ) : (
                                                                <span className='course-card-initials fw-bolder font-28 mb-2 me-1 text-white'>{getInitials(user?.name)}</span>
                                                            )}
                                                        </span>
                                                    );
                                                })}
                                                {course?.completed_user_list?.length > 5 && (
                                                    <span className="mb-2 count-circle">
                                                        {course?.completed_user_list?.length - 1}+
                                                    </span>
                                                )}
                                            </div>
                                            <p>You are the first one to complete the course </p>
                                        </div> :
                                       <div className="my-4"><div className='d-flex justify-content-start align-items-start'>
                                       {course?.completed_user_list?.slice(0, 5).map((user, index) => {
                                           return (
                                               <span className="mb-2" key={index}>
                                                   {user?.pic && user?.pic !== "null" && user?.pic !== null ? (
                                                       <img src={user?.pic} alt={user?.name} className="mb-2 me-0" style={{ height: "28px", width: "28px", borderRadius: "50%" }} />
                                                   ) : (
                                                       <span className='course-card-initials fw-bolder font-28 mb-2 me-0 text-white'>{getInitials(user?.name)}</span>
                                                   )}
                                               </span>
                                           );
                                       })}
                                       {course?.completed_user_list?.length > 5 && (
                                           <span className="mb-2 count-circle">
                                               {course?.completed_user_list?.length - 1}+
                                           </span>
                                       )}
                                   </div>
                                           <p>{course?.completed_user_list?.length} <span>{course?.completed_user_list?.length > 0 && course?.completed_user_list?.length !== 1 ? 'employees' : 'employee'}</span> have completed course so far!</p>
                                       </div>
                                   }

                                   {courseDetails && courseDetails?.status == 'notstarted' ? <div>
                                       <button type="button" className="btn btn-md btn-primary w-75"
                                       onClick={() => startCourseList(courseDetails)}
                                       >Get Started</button>
                                   </div> : courseDetails?.status == 'inprogress' ?
                                       // null
                                       <div>
                                           {/* console.log(courseDetails) */}
                                           <a className="btn btn-md btn-primary px-2 w-75" onClick={() => redirectUserToLMS(course)}>Continue Course</a>
                                       </div> :
                                       <div>
                                           <button type="button" className="btn btn-md btn-primary px-2 w-75" onClick={() => redirectUserToLMS(course)}>Completed</button>
                                       </div>
                                   }

                                   {/* <p className="mb-2"><a href="/learning_space/course_modules" className="btn btn-md w-75 btn-primary">Get Started</a></p> */}
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           <div className="row m-0 p-lg-4">
               <div className="col-md-8 pe-lg-5 ms-0">
                            <div className="course-content my-4">

                    {/*courseDetails?.contents?.map(data => {
                        return <div dangerouslySetInnerHTML={{ __html: data }} />
                     })

                       {renderContent(combinedContent)}
                       {combinedContent.length > limit && (
                           <button className="btn btn-link text-black fw-bold text-decoration-none p-0" onClick={handleViewMore}>
                               {showFullContent ? 'View Less' : 'View More'}<span className="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" stroke="black" strokeWidth="2" viewBox="0 0 24 24">
                                   <path fill="#000" d="M7.293 4.707L14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                               </svg>
                               </span>
                           </button>
                       )} */}

                                <div dangerouslySetInnerHTML={{ __html: courseDetails?.contents?.[1] }} />

                            </div>
                   {/* <p className="mb-4 text-justify" style={{ background: 'red' }}>Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna.Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. View More </p> */}
                   <hr className="my-4" />
                   <h3 className="fw-bold font-20">Course Content</h3>
                   <div className="d-flex justify-content-between align-items-center py-3">
                       <p style={{width: '57%'}}><span className="mx-2 text-capitalize">{courseDetails?.total_modules} Sections</span>.<span className="mx-2 text-capitalize">{courseDetails?.total_lectures} Lectures</span>.<span className="mx-2 text-capitalize">{courseDetails?.duration} total length</span></p>
                       <p className="text-end" style={{width: '27%'}}><a href="javascript:void(0)" className="fw-bold text-decoration-none" onClick={handleExpandAll}>Expand all the sections</a></p>
                   </div>

                   <div className="accordion mb-5" id="accordionExample">

                       {courseDetails?.modules && courseDetails?.modules?.map((module, i) => {
                           return <div className={`accordion-item ${expandAll || i === expandedAccordian ? 'show' : ''}`} key={i}>
                               <h2 className="accordion-header" id={i}>
                                   <button onClick={() => handleExpandAccordian(i)} className="accordion-button py-4" type="button" data-bs-toggle="collapse" data-bs-target={'#' + module.title} aria-expanded={expandAll || i === expandedAccordian} aria-controls={module.title}>
                                       <span className="fw-bold text-capitalize font-20">Section {i + 1} : {module?.title}</span>
                                       <span className="ms-auto text-capitalize">{module?.total_lectures} Lectures </span>
                                       {/* <span className="ms-auto">{module?.total_lectures} Lectures . {module?.duration} mins</span> */}
                                   </button>
                               </h2>
                               <div id={module.title} className={`accordion-collapse collapse ${i === expandedAccordian ? 'show' : ''}`} aria-labelledby={i} data-bs-parent="#accordionExample">
                                   <div className="accordion-body">
                                       <ul>
                                           {module?.sub_modules && module?.sub_modules.map(submodule => {
                                               return <li className="d-flex flex-column justify-content-between align-items-start mb-3">
                                               
                                                   <div className="d-flex justify-content-between align-items-center w-100">
                                                       <span>
                                                           {submodule?.module_type == "video" ?
                                                                <img src="/images/video.svg" alt="video" className='module-icon me-2' /> : submodule?.module_type == "test" ?  <img src="/images/quiz.svg" alt="quiz" className='module-icon me-2' /> :  <img src="/images/notes.svg" alt="notes" className='module-icon me-2' />}
                                                           <span className="font-16">
                                                             {submodule?.title}
                                                           </span>
                                                       </span>
                                                       <span className="text-dark-gray">{/*submodule?.duration*/}</span>
                                                   </div>

                                                   {submodule.sub_sub_modules && (
                                                       <ul id={submodule.title} className={`nestedsubmodule ps-3 w-100 accordion-collapse collapse `}>
                                                           {submodule.sub_sub_modules.map((nestedSubModule) => (
                                                               <li key={nestedSubModule.id} className={`my-3 pt-1 ps-3 w-100`}>
                                                                   <div className={`font-16 text-dark-grey text-decoration-none d-flex justify-content-between align-items-center`} >
                                                                       <span>
                                                                           {submodule?.module_type == "video" ?
                                                                               <img src="/images/video.svg" alt="video" className='module-icon me-2' /> : submodule?.module_type == "test" ? <img src="/images/quiz.svg" alt="quiz" className='module-icon me-2' /> : <img src="/images/notes.svg" alt="notes" className='module-icon me-2' />}
                                                                           {nestedSubModule.title}
                                                                       </span>
                                                                       <span className="text-dark-gray">{/*nestedSubModule?.duration*/}</span>
                                                                   </div>
                                                               </li>
                                                           ))}
                                                       </ul>
                                                   )}

                                               </li>
                                           })}
                                       </ul>
                                   </div>
                               </div>
                           </div>
                       })}
                   </div>
               </div>

           </div>
       </div>
           }
        </>
    )

}

export default CourseDetails;