import React, { useState, useEffect } from 'react';
import Menu from "./menu";
import styled from "styled-components";
import Footer from "./footer";
import Modal from 'react-modal';
import apiService from '../../services/apiService';
import CustomToast from '../Toast';

const Navbar = ({ children, isOrganisationDetails, showSidebar = true }) => {
    const [show, setShow] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [userData, setUserData] = useState(false);
    const [error, setError] = useState('');
    const [showOrganisationDetails, setShowOrganisationDetails] = useState(false);

    const fetchData = async () => {
        try {
            const token = apiService.getAuthToken()
            const result = await apiService.get('get-org-user-info', token);
            if(result && result.status == "success") {
                setUserData(result.data);
            }
        } catch (error) {
            setError('Error fetching user data');
            console.error(error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
        const checkSession = async () => {
            try {
               const token = apiService.getAuthToken() 
              const response = await apiService.get('check-user-session', token)
            } catch (error) {
              console.error('Error checking session:', error);
              if (error?.message == 'Invalid token id!') {
                // if(process.env.REACT_APP_ENV == "development") {
                //     window.location.href = `https://staging.inclusional.com`;
                //   } else {
                //     window.location.href = `https://site.inclusional.com`;
                // }
                window.location.href = process.env.REACT_APP_LOGIN_URL
              }
            }
          };
          checkSession();
    }, []);

    const openVideoModal = () => {
        setModalOpen(true);
    };

    const closeVideoModal = () => {
        setModalOpen(false);
    };

    const showSideBar = () => {
        setShow(!show);
    }

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('authToken');
        localStorage.removeItem("selectedCourseObj")
        localStorage.removeItem("selectedCourse")
        localStorage.removeItem("orgData")
        sessionStorage.removeItem("userDetail")
        localStorage.removeItem("ORGID")
        localStorage.removeItem("user_details")
        localStorage.removeItem("inviteCourseList")
        localStorage.removeItem("image")
        localStorage.removeItem("inviteData")
        localStorage.removeItem("selectedLanguageInfo")
        window.location.href = process.env.REACT_APP_LOGIN_URL
        // if(process.env.REACT_APP_ENV == "development") {
        //     window.location.href = `https://staging.inclusional.com`;
        //   } else {
        //     window.location.href = `https://site.inclusional.com`;
        // }
    };

    const SidebarData = [
        {
            title: "Dashboard",
            path: "/dashboard",
            icon: "/images/dashboard.svg"
        },
        {
            title: "Learning Space",
            path: "/learning_space",
            icon: "/images/book.svg"
        },
        {
            title: "Manage Users",
            path: "/manage_user",
            icon: "/images/user.svg"
        },
        {
            title: "Org. Admin",
            path: "/organisation_admin",
            icon: "/images/setting.svg"
        }
    ]

    useEffect(() => {
        const handleScroll = () => {
            let footer = document.querySelector('.footer');
            let stickyButton = document.querySelector('.sticky-btn');

            if (stickyButton) {

                if (window.innerHeight - footer.getBoundingClientRect().top > 0) {
                    stickyButton.style.bottom = window.innerHeight - footer.getBoundingClientRect().top + 20 + 'px';
                } else {
                    stickyButton.style.bottom = '20px';
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getInitials = (name) => {
        return name?.split(' ').map(word => word.charAt(0)).join(' ').toUpperCase() || '';
    };

    return (
        <>
            <header className={`header ${show ? "add_body_padding" : ""}  `} id="admin-dash-header">
                <a href='/'> <img src="/images/logo.svg" alt="logo" className="navbar_logo" /></a>

                <div className='header_toggle'>
                    <i id="header-toggle"></i>
                </div>
                <div className="dropdown sidebar-profile d-flex align-items-center justify-content-center">
                    <div className="language-picker">
                        <form action="" className="language-picker__form">
                            <select
                                id="language-picker"
                                name="language-picker"
                                className='form-control custom-select'
                                value={selectedLanguage}
                                onChange={(e) => setSelectedLanguage(e.target.value)}>
                                <option lang="" value="english">ENG</option>
                            </select>
                        </form>
                    </div>
                    <p className='username mb-0 ms-3'>Hello, {userData.name}</p>
                    <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                    {userData && userData?.pic && userData?.pic != "null"  && userData?.pic != null ? (
                                    <img src={userData.pic} alt="avatar" className="avatar rounded-circle" />
                                ) : (
                                    <div className='initials font-28 text-white fw-bold text-uppercase'>{getInitials(userData?.name)}</div>
                                )}
                    </span>
                    <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                        <li>
                            <a className="dropdown-item" href="/my_profile">
                                My Profile
                            </a>
                        </li>
                        <li>
                            <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </div>
            </header>

            <div className='wrapper'>
                {showSidebar && (
                    <aside className={`sidebar ${show ? "review" : ""} `} id="admin-dash-nav">
                        <div className='d-flex flex-column justify-content-between' style={{minHeight: '100vh'}}>
                            <div className='sidebar-menu'>
                                {SidebarData.map((item, index) => {
                                    return <Menu item={item} key={index} />;
                                })}
                            </div>

                            <div className='sticky-btn text-left ms-1 pe-4'>
                                <button onClick={openVideoModal} className='btn btn-primary p-2 w-100'>Watch Tutorial</button>
                            </div>

                            <Modal
                                isOpen={isModalOpen}
                                onRequestClose={closeVideoModal}
                                contentLabel="Video Modal"
                                className='videoModal w-75'
                            > <button type="button" className="close text-white" onClick={closeVideoModal}> <svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                            <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                          </svg></button>
                                <video autoPlay className="w-100" controls controlsList="nodownload">
                                    <source src="https://inclusional.s3.ap-south-1.amazonaws.com/project_dev/project_dev/videos/2024-02-06 12:37:30-VID-20240123-WA0001.mp4" type="video/mp4" />
                                </video>
                            </Modal>

                        </div>
                    </aside>
                )}

                <main className={` ${show ? "add_body_padding" : "main"} `}>
                    {children}
                </main>
            </div>

            <Footer></Footer>
        </>
    );
}

export default Navbar;
