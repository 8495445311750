import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import './dashboard.css';

const BarChart2 = () => {
 
  const customTooltipRenderer = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl2 = chart.canvas.parentNode.querySelector('.custom-tooltip2');

    if (!tooltipEl2) {
      return;
    }

    if (tooltip.opacity === 0) {
      tooltipEl2.style.opacity = 0;
      return;
    }

    const position = chart.canvas.getBoundingClientRect();

    const datasetClasses = tooltip.dataPoints.map((point, index) => `dataset-${index + 1}`).join(' ');

    tooltipEl2.innerHTML = `
      <div class="tooltip-content ${datasetClasses}">
        <div class="p-3">
          <p class="mb-1">${tooltip.body.map(bodyLine => `<strong>${bodyLine.lines.join(': ')}</strong>`).join('')}</p>
          <p class="mb-0 font-14">Licences remaining</p>
        </div>
        <a href="" class="text-decoration-none">Click on column for action</a>
      </div>
    `;

    const tooltipWidth = tooltipEl2.clientWidth;
    const tooltipHeight = tooltipEl2.clientHeight;

    let left = position.left + window.pageXOffset + tooltip.caretX - tooltipWidth / 1.3;
    let top = position.top + window.pageYOffset + tooltip.caretY - tooltipHeight - 1350;

    left = Math.min(Math.max(left, 0), document.body.clientWidth - tooltipWidth);
    top = Math.min(Math.max(top, 0), document.body.clientHeight - tooltipHeight);

    tooltipEl2.style.opacity = 1;
    tooltipEl2.style.left = `${left}px`;
    tooltipEl2.style.top = `${top}px`;
  };

  const data = {
    labels: ['Licenses Bought', 'Invitation Sent', 'Invite Accepted', 'In Progress', 'Course Completed'],
    datasets: [
      {
        backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
        borderColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
        data: [300, 250, 200, 150, 100],
        barThickness: 50,
        stack: 'stack1', 
      },
      {
        backgroundColor: ['#146B90', '#F2D7D7', 'rgba(244, 181, 50, 0.4)', 'rgba(245, 147, 144, 0.4)', 'rgba(34, 162, 152, 0.4)'],
        borderColor: ['#146B90', '#F2D7D7', 'rgba(244, 181, 50, 0.4)', 'rgba(245, 147, 144, 0.4)', 'rgba(34, 162, 152, 0.4)'],
        data: [0, 50, 50, 50, 50], 
        barThickness: 50,
        stack: 'stack1', 
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: customTooltipRenderer,
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className='statistics mt-5 p-5'>
      <h3 className='fw-bold font-22 mb-4'>Statistics</h3>
      
      <Bar type="bar" data={data} options={options} />

      <div className="custom-tooltip2"> </div>
    </div>
  );
};

export default BarChart2;
