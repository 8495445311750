import React from 'react';

const ErrorModal = ({ errorMessage }) => {
  return (
    <div className="error-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Error</h5>
          <button type="button" className="btn-close" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <p>{errorMessage}</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;